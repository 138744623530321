import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../components/test.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/new-york',
    name: 'new-york',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/new-york.vue')
  },
  {
    path: '/southampton',
    name: 'southampton',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/southampton.vue')
  },
  {
    path: '/southampton/rooms',
    name: 'southampton/rooms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/rooms.vue')
  },
  {
    path: '/southampton/restaurant',
    name: 'southampton/restaurant',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/restaurant.vue')
  },
  {
    path: '/southampton/gallery',
    name: 'southampton/gallery',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/gallery.vue')
  },
  {
    path: '/southampton/contact',
    name: 'southampton/contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/contact.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// const siteLinkComponent = document.querySelectorAll(".site-link-component");
// const mainContent = document.querySelector(".main-content");

// for (let i = 0; i < siteLinkComponent.length; i++) {
//     siteLinkComponent[i].addEventListener("click", function () {
//         mainContent.style.opacity = "0";
//         setTimeout(function() {
//             router.push(siteLinkComponent[i].href);
//             mainContent.style.opacity = "1";
//         }, 300)
//     });
// }

export default router
