<template>
  <nav class="navbar-component">
      <div class="desktop-logo-container">
          <a class="site-link-component desktop-logo router-link-exact-active active" v-on:click="click('/', $event)" href="/">
            <img src="./assets/images/logo.svg" alt="">
          </a> 
          <name></name>
      </div>
      
      <div class="navbar-mobile-header">
              <a class="navbar-mobile-header-logo" v-on:click="click('/', $event)" href="/"></a>
              <name></name>
              <button class="navbar-mobile-header-toggle" style="">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="sr-only">Toggle navigation</span>
              </button>
      </div>

      <div class="navlinks">
          <div class="navlinks-wrapper">
                <a href="/southampton" v-on:click="click('/southampton', $event)" class="site-link-component navlink">Home</a>
                <a href="/southampton/rooms" v-on:click="click('/southampton/rooms/', $event)" class="site-link-component navlink">Rooms</a>
                <a href="/southampton/restaurant" v-on:click="click('/southampton/restaurant/', $event)" class="site-link-component navlink">Restaurant</a>
                <a href="/southampton/gallery" v-on:click="click('/southampton/gallery/', $event)" class="site-link-component navlink">Gallery</a>
                <a href="/southampton/contact" v-on:click="click('/southampton/contact/', $event)" class="site-link-component navlink">Contact</a>
          </div>
      </div>
  </nav>
  <div class="main-content">
      <router-view/>
  </div>

  <footer class="footer-component" style="">
    <div class="footer-component-top-divider"></div>
    <div class="footer-component-content">
        <div class="footer-component-content-row">
            <span class="footer-component-content-row-title">New York</span>
            <span class="footer-component-content-row-detail">
                <a href="tel:+16464845356">
                    646.484.5356
                </a>
            </span>
            <span class="footer-component-content-row-detail">
                116 East 60th Street,<br>
                New York, NY 10022
            </span>
        </div>
        <div class="footer-component-content-row">
            <span class="footer-component-content-row-title">
                Southampton
            </span>
            <span class="footer-component-content-row-detail">
                <a href="tel:+16315009276">
                    631.500.9276
                </a>
            </span>
            <span class="footer-component-content-row-detail">
                136 Main Street,<br>
                Southampton, NY 11968
            </span>
        </div>
    </div>
  </footer>
</template>

<style lang="scss">

</style>

<script>


import name from "./components/name.vue"

export default {
    methods: {
        click(path, event) {
            event.preventDefault();
            document.querySelector(".main-content").style.opacity = "0";

            setTimeout(function() {
                this.$router.push(path);
                document.querySelector(".main-content").style.opacity = "1";

                setTimeout(function() {
                    navlinkHover();
                    if (window.location.href === (document.location.protocol + "//" + document.location.host + "/"))  {
                        document.querySelector(".navbar-component").style.display = "none";
                    }
                    else {
                        document.querySelector(".navbar-component").style.display = "block";
                        document.querySelector(".navlinks").style.display = "flex";
                    }
                }, 100);

            }.bind(this), 500);

            function navlinkHover() {
                const navlink = document.querySelectorAll(".navlink");

                navlink.forEach(element => {
                    element.classList.remove("active");
                });
                for (let i = (navlink.length - 1); i >= 0; i--) {
                    if (window.location.href.includes(navlink[i].href.split("/").pop())) {
                        navlink[i].classList.add("active");
                        break;
                    }
                }
            }
        }
    },
    mounted() {
        window.onload = function () {
            const navlink = document.querySelectorAll(".navlink");

            navlink.forEach(element => {
                element.classList.remove("active");
            });
            for (let i = (navlink.length - 1); i >= 0; i--) {
                if (window.location.href.includes(navlink[i].href.split("/").pop())) {
                    navlink[i].classList.add("active");
                    break;
                }
            }

            if (document.querySelector(".navbar-mobile-header-toggle")) {
                document.querySelector(".navbar-mobile-header-toggle").addEventListener("click", function() {
                    document.querySelector(".navlinks").classList.toggle("open");
                })
            }
        }
    },
    components: {name}
}

</script>
