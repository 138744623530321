<template>

    <div ref="name" class="desktop-logo-container-site-title">
        {{name}}
    </div>

</template>

<script>

  export default {
    data() {
      return {
        name: "southampton",
      }
    },
    watch: {
      '$route.path'() {
        if (this.$route.path == "/new-york") {
          this.name = "New York";
        }
        else if (this.$route.path.includes("southampton")){
          this.name = "southampton";
        }
        else {
          this.name = "";
        }
      }
    }
  }

</script>